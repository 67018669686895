/*timerOne*/
.mainContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background: black;
    padding: 6px 16px;
    gap: 10px;
    width: fit-content;
    border-radius: 8px;
}

.timerContainer {
    background: #000;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timer {
    display: flex;
    width: 26px;
    height: 28px;
    background: #FFFFFF;
    color: black;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

/*timerTwo*/


.mainContainer2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background: #FFFFFF;
    padding: 6px 16px;
    gap: 10px;
    width: fit-content;
    border-radius: 8px;
}

.timerContainer2 {
    background: #FFFFFF;
    color: #FF9930;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    align-items: center;
}

.timer2 {
    display: flex;
    width: 17px;
    height: 22px;
    color: #FFFFFF;
    background: #FF9930;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
}


.timerTwo {
    display: flex;
    padding-bottom: 2px;
    gap: 4px;
}


/*timerThree*/
.mainContainer3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    background: #B7DC06;
    padding: 0 16px 6px 16px;
    gap: 10px;
    width: fit-content;
    border-radius: 8px;
    height: 52px;
}

.timerContainer3 {
    background: #FFFFFF;
    color: #1B4C7D;
    display: flex;
    /*padding: 4px 15px;*/
    height: 30px;
    width: 48px;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    justify-content: center;
}

.timer3 {
    display: flex;
    width: 38px;
    background: #B7DC06;
    color: #1B4C7D;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-size: 10px;
    position: relative !important;
    top: 6px;
}

.timerThree {
    display: flex;
    align-items: center;
    flex-direction: column;
}
