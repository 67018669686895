.paper {
    width: 70vw;

    @media (--desktop) {
        width: 35vw !important;
    }
}

.paperRight {
    border-left: 1px solid #3c3c3c;
    border-top: 1px solid #3c3c3c;
}

.top {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding:var(--m-spacing) var(--l-spacing);
    padding-bottom: var(--l-spacing);
}

.section {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px;
    margin: 16px;
    position: relative;
}

.clickable {
    display: flex;
    font-size: var(--d-font-size) !important;
    color: var(--black70) !important;
    text-transform: capitalize !important;
    font-weight: var(--medium-bold) !important;
    margin-bottom: 24px !important;
    padding: 0 16px !important;
    align-items: center;
}

.middle {
    display: flex !important;
    flex-direction: column !important;
    height: 50%;
}

.name {
    width: calc(100% - 45px);
    text-align: left;
    padding-left: 12px !important;
}

.info {
    font-size: var(--d-font-size) !important;
    margin: 0 8px !important;
    margin-bottom: 8px !important;
}

.end {
    display: flex;
    flex-direction: column;
}

.img {
    width: 66px !important;
    height: 66px !important;
    object-fit: fill !important;
    border-radius: 50%;
}

.divider1 {
    position: relative !important;
    left: 16px !important;
    right: 16px !important;
    width: calc(100% - 32px) !important;
    color: var(--black110) !important;
    margin-bottom: 24px !important;
}

.desc {
    font-size: var(--h5-font-size);
    color: var(--black1);
    font-weight: var(--medium-bold);
    padding: 8px 0;
}

.email {
    font-size: var(--m-font-size);
    color: var(--black150);
}

.logout {
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit {
    position: absolute;
    right: 16px;
    top: 16px;
}

.defaultUser {
    background: var(--secondary);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    height: 46px;
    width: 46px;
    padding: var(--l-spacing);
    border-radius: 50%;
    margin-bottom: var(--m-spacing);
    display: flex;
    align-items: center;
    justify-content: center;
}

.defaultIcon {
    width: 24px;
    height: 22px;
}

.closeIcon{
    top:0px;
    margin-left: auto;
    right: 0px;
    margin-bottom: 12px;
}

.options{
    border-bottom: 1px solid var(--black110);
    padding:16px 0;
}

.spacerh{
    margin:0 4px;
}


.mainTitle {
    font-weight: var(--medium-bold);
    margin: var(--l-spacing) 0;
    font-size: var(--d-font-size);
    cursor: pointer;
    width: 100%;
    color: #212121 !important;
  }
  
  .mainTitle:focus {
    outline: none;
    color: var(--primary);
  }
  
  .subMenu {
    font-size: var(--m-font-size) !important;
    display: none;
    margin: var(--l-spacing);
    font-weight: var(--medium-bold) !important; 
  }
  
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: var(--s-spacing);
    margin: var(--l-spacing) 0px;
    color: var(--black8);
    padding-left: 0px !important;
  }
  
  .link:hover, .active {
    color: var(--primary);
  }
  
  .show {
    display: block;
  }
  
  .flex {
    display: flex;
    align-items: center;
  }

  .flex:last-child {
    border-bottom: none !important;
}
  
  .icon{
    padding-right: var(--xl-spacing);
    width: 22px;
    height: 22px;
  }
  
  
  .chevron {
    float: right;
  }

  .subMenuText {
    font-size: var(--d-font-size) !important;
    font-weight: var(--medium-bold) !important;
    color: var(--black80);
      margin-left: var(--s-spacing);
  }


  .flex2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .footer{
    bottom: 40px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .wlogo{
      margin :0 6px;
  }
  
  .tnc {
      font-size: 10px;
      color: var(--black70);
      text-align: center;
  }

  .icons{
      width: 22px;
      height: 22px;
  }

  .spacer {
      margin: 20px 0;
  }

  .menu {
      border-bottom: 1px solid var(--black110);
  }

  .emailMenu{
      margin-top: -6px !important;
  }

  .marginLeft{
      margin-left: var(--xxl-spacing);
      font-size: var(--s-font-size);
      color: var(--black60);
      margin-bottom: var(--xxl-spacing);
  }

.text2{
    font-weight: var(--medium-bold);
    color: var(--black60);
    text-align: center;
    line-height: 1.5;
}

.isDirect {
    display: none;
}