.chatButton {
    margin-left: 12px !important;
    @media (--desktop) {
        margin-left: var(--xxl-spacing) !important
    }
}

.chatButton img {
}

.unread {
    position: relative;
    top: -20px;
    left: -16px;
    display: block;
    border-radius: 50%;
    text-align: center;
    line-height: 2.5;
    font-size: var(--xs-font-size);
    border: 1px solid var(--white);
    width: 16px;
    height: 16px;
    background: var(--grey);
}

.chatButtonPosition {
    position: fixed;
    right: 60px;
    bottom: 60px;
    z-index: 9999999;
        @media (--desktop) {
            position: fixed;
            right: 60px;
            bottom: 60px;
            z-index: 9999999;
        }
}
