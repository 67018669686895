.field input {
    color: var(--black);
    padding: 16px;
    font-size: var( --d-font-size);
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 1px rgb(0 0 0 / 10%);
    border-radius: 100px;
    margin-right: 8px;
    font-weight: 400;
}

.field fieldset {
    border: 0;
}

.container {
    position: sticky;
    bottom: 0;
    display: flex;
    flex-direction: row;
    padding: var(--xs-spacing) var(--s-spacing);
    align-items: center;
    justify-content: center;
}

.button {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    min-width: 36px;
    max-width: 36px;
    height: 36px;
    max-height: 36px;
    margin-left: var(--s-spacing);
    cursor: pointer;
    background: var(--primary);
    padding: 8px;
}
