.container {
    padding: 16px;
    overflow: hidden;
    background: var(--secondary);
    width: calc(100vw - 32px);
    height: calc(100vh - 32px);

    @media (--desktop) {
        width: calc(100vw - 32px) !important;
    }
}

.body {
    height: 100%;
}

/* .dateBlock {
    display: flex;
    flex-direction: column;
    padding: 0 var(--s-spacing);
} */
/*
.dateHeading {
    text-align: center;
    color: rgba(33, 33, 33, 0.5);
    font-size: var(--s-font-size);
} */

.chatRoom {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 204px);
    overflow: auto;
}

.otherUser img {
    border-radius: 50%;
}

.otherUser {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.otherUser .message, .you .message {
    padding: 12px 16px;
    font-size: var(--d-font-size);
    max-width: 60vw;
    color: var(--black);
    border-radius: 6px;
    margin-bottom: 4px;
    background: var(--black110);
    border-radius: 40px;
    position: relative;
}

.otherUser .message {
    margin-right: 0;
    border-bottom-left-radius: 0;
}

.you {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.youSec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nameImgSec {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.you .message {
    background: var(--black110);
    border-bottom-right-radius: 0;
}

.chat {
    margin: 10px 0;
}

.at {
    text-transform: capitalize;
    padding: var(--xxs-spacing);
    padding-right: 0;
    width: 100%;

    font-size: var(--m-font-size);
    text-align: center;
    color: var(--black60);
}

.shop {
    background-color: var(--secondary) !important;
    color: var(--black) !important;
    height: 60px;
    width:  60px;
    margin-right: 12px;
}

.shopSection {
    display: flex;
    padding: 16px 0;
    color: var(--black);
    font-size: var(--h5-font-size);
    align-items: center;
}

.otherUser .at {
    text-align: right;
}

.loadMoreContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: var(--m-spacing);
    padding-top: 0;
}

.loadMore {
    display: inline-block;
}

.btn {
    background-color: var(--secondary) !important;
    color: var(--primary) !important;
    text-transform: capitalize !important;
}

.noMsg {
    text-align: center;
    font-weight: 500;
    font-size: var(--h5-font-size);
    color: var(--black70);
    padding-top: 30px;
    padding-bottom: 12px;
}

.noMsg1 {
    text-align: center;
    font-weight: 500;
    font-size: var(--d-font-size);
    color: var(--black50);
}

.emptySec {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
}
