.helloBar {
    background-position: center center;
    background-size: cover;
    padding: var(--m-spacing);
    color: var(--black);
    font-size: var(--m-font-size);
    font-weight: var(--semi-bold);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.one {
    position: absolute;
    top: 0;
    left: 20%;
}

.two {
    position: absolute;
    top: 0;
    left: 40%;
}

.three {
    position: absolute;
    top: 0;
    left: 75%;
}

.four {
    position: absolute;
    bottom: 0;
    left: 30%;
}

.five {
    position: absolute;
    bottom: 0;
    left: 70%;
}

.s_x {
    position: absolute;
    right: var(--l-spacing);
    bottom: var(--l-spacing);
}

.button {
    color: var(--hellobarBtnColor) !important;
    background: var(--hellobarBtnBgColor) !important;
    padding: var(--s-spacing) var(--m-spacing) !important;
    border-radius: var(--border-radius) !important;
    font-size: var(--d-font-size) !important;
    margin-left: var(--l-spacing) !important;
}

.text {
    svg {
        margin-right: var(--s-spacing);
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > *:first-child {
        margin-left: var(--l-spacing);
    }

    > *:last-child {
        margin-right: var(--l-spacing);
    }

    @media (--desktop) {
        flex-direction: row;
    }

 }

.textBar {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.timer {
    padding: var(--s-spacing) var(--m-spacing) !important;
}
